<template>
  <div>
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات پروفایل تخصصی..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <h5 class="farmuserSubForm-title">
      جزئیات اطلاعات کارمند مزرعه {{ farmuserName }}
    </h5>
    <div class="farmuserDetailInfo" v-if="farmUserDetail != null">
      <div class="detail-info">
        <span>
          <h5>سابقه کار : {{ farmUserDetail.workExperience }} سال</h5>
        </span>
        <p>
          <i>متن معرفی کارمند مزرعه</i>
          {{ farmUserDetail.text }}
        </p>
      </div>
      <div class="detail-expertise">
        <h5>تخصص های کارمند مزرعه</h5>
        <h6 v-for="(ex, index) of farmUserDetail.expertise" :key="index">
          {{ ex.item1 }}
        </h6>
      </div>
    </div>
    <h6 v-else class="noData">کارمند مزرعه پروفایل تخصصی خود را تکمیل نکرده است</h6>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      farmUserDetail: {
        workExperience: 0,
        text: "",
        expertise: [],
      },
    };
  },
  props: ["farmuserId", "farmuserName"],
  watch: {
    farmuserId: function () {
      this.GetDetail();
    },
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerFarmuser", [
      "FarmerFarmuserDetailInfo",
    ]),

    async GetDetail() {
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.FarmerFarmuserDetailInfo({
        farmuserId: this.farmuserId,
      });
      if (
        result.data.expertise &&
        result.data.workExperience > 0 &&
        result.data.text
      ) {
        this.farmUserDetail = result.data;
      } else {
        this.farmUserDetail = null;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.GetDetail();
  },
  created() {},
};
</script>

<style scoped>
.farmuserSubForm-title {
  float: right;
  width: 100%;
  text-align: right;
  padding: 8px 5px 7px 0px;
  font-size: 1em;
  background: linear-gradient(272deg, #c7f5c9, transparent);
  color: darkgreen;
}
.farmuserDetailInfo {
  float: right;
  height: 37vh;
  width: 49vw;
}
.detail-info {
  float: right;
  width: 60%;
  height: 36vh;
  margin: 0.7em;
  border-right: 1px solid green;
  padding-right: 1em;
}
.detail-info span {
  float: right;
  width: 100%;
  background-color: #0dff0d;
  color: #373737;
}
.detail-info span h5 {
  float: right;
  font-family: "Dirooz";
  padding: 0.3em 1em 0em 0em;
  font-size: 1em;
  font-weight: bold;
}
.detail-info span h6 {
  float: right;
}
.detail-info p {
  float: right;
}
.detail-expertise {
  float: right;
  width: 37%;
  height: 37vh;
  background-color: whitesmoke;
}
.detail-expertise h5 {
  float: right;
  width: 91%;
  margin: 1em;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  font-family: "Dirooz";
}
.detail-expertise h6 {
  float: right;
  width: 96%;
  margin: 0.4em;
  border-right: 2px solid green;
  padding-right: 0.3em;
  padding: 0.2em 0.3em;
  background-color: #fff;
}
.noData {
  width: 100%;
  text-align: center;
  margin-top: 3em;
  float: right;
  font-size: 2em;
  color: palevioletred;
  font-family: "Dirooz";
}
</style>
