import { render, staticRenderFns } from "./farmuserDetail.vue?vue&type=template&id=544cfe06&scoped=true"
import script from "./farmuserDetail.vue?vue&type=script&lang=js"
export * from "./farmuserDetail.vue?vue&type=script&lang=js"
import style0 from "./farmuserDetail.vue?vue&type=style&index=0&id=544cfe06&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544cfe06",
  null
  
)

export default component.exports