<template>
  <div>
    <div class="allFarmusersList">
      <h5 class="allFarmusersListTitle">لیست کارمندان مزرعه مرتبط با شما</h5>
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <div class="farmuserInfoBox" v-if="farmuserList.length > 0">
        <div
          :class="
            farmuserId == item.farmuserId
              ? 'farmuserInfo active'
              : 'farmuserInfo'
          "
          v-for="(item, index) of farmuserList"
          :key="index"
        >
          <h4>نام و نام خانوادگی</h4>
          <h5>{{ item.fullName }}</h5>
          <h4>شماره تلفن</h4>
          <h5>{{ item.phone }}</h5>
          <h4>استان (شهرستان)</h4>
          <h5>{{ item.stateCity }}</h5>
          <h4>تاریخ تولد</h4>
          <h5>{{ item.birthDate }}</h5>
          <h4>تعداد زمین ها</h4>
          <h5>{{ item.farmCount }} عدد زمین</h5>
          <h4>تحصیلات</h4>
          <h5>{{ item.educatuin }}</h5>
          <button
            class="bg-info"
            @click="
              GetSubForm(item.farmuserId, item.fullName, 'farmuserDetail')
            "
          >
            پروفایل تخصصی
          </button>
          <button
            class="bg-info"
            @click="GetSubForm(item.farmuserId, item.fullName, 'farmuserTips')"
          >
            آخرین دستورالعمل
          </button>
          <button
            class="bg-info"
            @click="GetSubForm(item.farmuserId, item.fullName, 'farmuserFarms')"
          >
            لیست زمین ها
          </button>
          <button
            class="bg-danger"
            @click="DeleteFarmuser(item.farmuserId, item.fullName)"
          >
            اتمام همکاری
          </button>
        </div>
      </div>
      <div v-else>
        <img
          src="../../../assets/img/noData.png"
          alt=""
          style="margin: 12em 12em 0em 0em"
        />
        <h6
          style="
            text-align: center;
            font-family: 'Dirooz';
            font-size: 2em;
            color: #bbb;
          "
        >
          هیچ کارمند مزرعه ثبت نشده است
        </h6>
      </div>
    </div>
    <div class="actionBox">
      <div class="farmuserSubForm">
        <component
          v-if="subForm != null"
          v-bind:is="subForm"
          :farmuserId="farmuserId"
          :farmuserName="farmuserName"
        />
        <div v-else>
          <img
            style="margin: 1em; float: right; width: 4em"
            src="../../../assets/img/nodetailData.png"
            alt=""
          />
          <h6
            style="
              float: right;
              margin: 1em 0.3em;
              font-size: 2em;
              color: #c0c0c0;
              font-family: 'IRANSansWeb';
            "
          >
            برای نمایش جزئیات ؛ کارمند مزرعه را انتخاب کنید
          </h6>
        </div>
      </div>
      <farmuserNew
        @realodList="GetAll()"
        :farmuserId="farmuserId"
        :farmuserName="farmuserName"
      ></farmuserNew>
    </div>
  </div>
</template>

<script>
import farmuserFarms from "./farmuserFarms";
import farmuserDetail from "./farmuserDetail";
import farmuserTips from "./farmuserTips";
import farmuserNew from "./farmuserNew";
import { mapActions } from "vuex";

export default {
  components: {
    farmuserFarms,
    farmuserDetail,
    farmuserTips,
    farmuserNew,
  },
  data() {
    return {
      farmuserList: [],
      modelShowState: false,
      modalTitle: "",
      subForm: null,
      loading: false,
      farmuserId: 0,
      farmuserName: "",
      loadingText: "در حال دریافت اطلاعات ...",
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerFarmuser", [
      "GetAllFarmerFarmuser",
      "FarmerFarmuserDelete",
    ]),

    async GetAll() {
      this.farmuserList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.GetAllFarmerFarmuser({ searchName: "" });
      if (result.data) {
        this.farmuserList = result.data;
      }
      this.loading = false;
    },
    GetSubForm(farmuserId, farmuserName, subForm) {
      this.farmuserId = farmuserId;
      this.farmuserName = farmuserName;
      this.subForm = subForm;
    },
    async DeleteFarmuser(farmuserId, farmuserName) {
      this.$confirm({
        message: `با کارمند مزرعه ${farmuserName} قطع همکاری میکنید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال حذف کارمند مزرعه...";
            let result = await this.FarmerFarmuserDelete({
              farmuserId: farmuserId,
            });
            if (result.succeeded) {
              this.$notify({
                title: "پیام سیستم",
                text: `کارمند مزرعه از لیست کارمندان حذف شد`,
                type: "success",
              });
              this.GetDetail(0);
              this.GetAll();
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message}`,
                type: "error",
              });
            }
            this.loading = false;
          }
        },
      });
    },
  },
  mounted() {
    this.GetAll();
  },
  created() {},
};
</script>

<style scoped>
.allFarmusersList {
  float: right;
  height: 86vh;
  width: 33vw;
  margin-top: 2vh;
  margin-right: 1vw;
  -webkit-box-shadow: 0px 0px 3px 1px #3c4b64;
  box-shadow: 0px 0px 14px -3px #3c4b64;
  background-color: #fff;
  border-radius: 5px;
}
.allFarmusersListTitle {
  float: right;
  width: 68%;
  text-align: right;
  margin-right: 10px;
  margin-top: 4px;
  border-right: 3px solid darkgreen;
  font-size: 13px;
  padding: 3px 7px 1px 0px;
  color: #007928;
  font-family: "Dirooz";
  border-radius: 0px 3px 3px 0px;
}

.actionBox {
  float: right;
  height: 86vh;
  width: 49vw;
  margin-right: 10px;
  margin-top: 2vh;
}
.farmuserSubForm {
  float: right;
  height: 42vh;
  width: 49vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 9px -4px #000;
  border-radius: 5px;
}

.farmuserInfoBox {
  height: 80vh;
  width: 33vw;
  overflow-y: auto;
  overflow-x: hidden;
}
.farmuserInfoBox::-webkit-scrollbar {
  width: 8px;
}
.farmuserInfoBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.farmuserInfoBox::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.farmuserInfoBox::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
.farmuserInfo {
  width: 95%;
  height: 21vh;
  border: 1px solid #008f30;
  margin: 10px 2.5% 0px 0px;
  border-radius: 9px;
  background-color: #fff;
  cursor: pointer;
  padding: 1em;
}
.farmuserInfo:hover {
  background-color: #cdffd9;
}
.farmuserInfo.active {
  background-color: #eaffef;
}

.farmuserInfo h4 {
  float: right;
  width: 7vw;
  clear: right;
  font-size: 1em;
  margin: 0.3em 0.2em;
  text-align: left;
  padding-left: 1em;
  border-bottom: 1px dashed #bbb;
  padding-bottom: 0.13em;
}
.farmuserInfo h5 {
  float: right;
  width: 12vw;
  font-size: 1em;
  margin: auto;
  text-align: right;
  padding-left: 1em;
  padding: 3px;
  color: #019934;
  border-bottom: 1px solid #2eb85c;
}
.farmuserInfo button {
  clear: left;
  width: 8vw;
  margin: 1em 2em 0 0;
  border: none;
  background-color: green;
  color: #fff;
}
</style>
