<template>
  <div class="addNewFarmuserBox">
    <div v-if="farmuserId > 0" class="addNewFarmuser">
      <h5>
        جهت افزودن کارمند مزرعه جدید کد کارمندی را وارد کرده و جستجوی کد را کلیک
        کنید
      </h5>
      <input
        @keyup.enter="GetWithCode()"
        type="text"
        v-model="identityCode"
        placeholder="کد کارمند مزرعه را اینحا وارد کنید"
      />
      <button @click="GetWithCode()">
        جستجوی کد <i class="fa fa-search" aria-hidden="true"></i>
      </button>
      <div class="farmuserDetailInfo">
        <div class="farmuserDetailInfoBox-info">
          <h4>نام و نام خانوادگی</h4>
          <h5>{{ farmuserItem.fullName }}</h5>
          <h4>تلفن همراه</h4>
          <h5>{{ farmuserItem.phone }}</h5>
          <h4>استان(شهرستان)</h4>
          <h5>{{ farmuserItem.stateCity }}</h5>
          <h4>تاریخ تولد</h4>
          <h5>{{ farmuserItem.birthDate }}</h5>
          <h4>تحصیلات</h4>
          <h5>{{ farmuserItem.education }}</h5>
          <h4>تلفن ثابت</h4>
          <h5>{{ farmuserItem.tel }}</h5>
          <p>
            <i>آدرس</i>
            {{
              farmuserItem.address == ""
                ? "آدرس کارمند مزرعه ثبت نشده است"
                : farmuserItem.address
            }}
          </p>
        </div>
        <div v-if="farmuserItem.text">
          <div class="farmuserDetailInfoBox-aboutmy">
            <i>توضیحات کارمند مزرعه</i>
            <p>
              {{ farmuserItem.text }}
            </p>
          </div>
          <div class="farmuserDetailInfoBox-expertise">
            <span>
              <i>تخصص های کارمند مزرعه</i>
              <h6 v-for="(ex, index) of farmuserItem.expertise" :key="index">
                {{ ex.item1 }}
              </h6> 
            </span>
          </div>
        </div>
        <div class="not-expertise" v-else>
          کارمند مزرعه پروفایل تخصصی خود را تکمیل نکرده است
        </div>
        <button @click="RegisterFarmuser()" class="register-btn">
          تایید و شروع همکاری
        </button>
      </div>
    </div>
    <div v-else class="search-box">
      <h6>
        جهت افزودن کارمند مزرعه جدید کد کارمندی را وارد کرده و جستجوی کد را کلیک
        کنید
      </h6>
      <input
        @keyup.enter="GetWithCode()"
        type="text"
        v-model="identityCode"
        placeholder="کد کارمند مزرعه را اینحا وارد کنید"
      />
      <button @click="GetWithCode()">
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      identityCode: "",
      farmuserItem: null,
      farmuserId: 0,
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerFarmuser", [
      "FarmerFarmuserGetInfoWithCode",
      "FarmerFarmuserRegister",
    ]),

    async GetWithCode() {
      if (this.identityCode) {
        this.farmuserItem = null;
        this.farmuserId = 0;
        this.loading = true;
        this.loadingText = "در حال دریافت اطلاعات...";
        let result = await this.FarmerFarmuserGetInfoWithCode({
          code: this.identityCode,
        });
        if (result.succeeded) {
          this.farmuserItem = result.data; 
          if(!this.farmuserItem.expertise){
            this.farmuserItem.expertise = [];
            this.farmuserItem.expertise.push({
              item1: "کارمند مزرعه تخصص های خود را مشخص نکرده است",
            })
          }
          this.farmuserId = result.data.farmuserId;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message}`,
            type: "info",
          });
        }
        this.loading = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `کد کارمندی را وارد کنید`,
          type: "error",
        });
      }
    },
    async RegisterFarmuser() {
      if (this.farmuserId > 0) {
        this.loading = true;
        this.loadingText = "در حال دریافت اطلاعات...";
        let result = await this.FarmerFarmuserRegister({
          farmuserId: this.farmuserId,
        });
        if (result.succeeded) {
          this.$emit("realodList");
          this.$notify({
            title: "پیام سیستم",
            text: `کارمند مزرعه ${this.farmuserItem.fullName} به کارمندان شما افزوده شد`,
            type: "success",
          });
          this.farmuserId = 0;
          this.farmuserItem = {};
          this.identityCode = null;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message}`,
            type: "info",
          });
        }
        this.loading = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `کد کارمندی را وارد کنید`,
          type: "error",
        });
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.addNewFarmuserBox {
  float: right;
  height: 42vh;
  width: 49vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 9px -4px #000;
  margin-top: 1em;
  border-radius: 5px;
}
.addNewFarmuser > h5 {
  float: right;
  text-align: right;
  padding: 8px 5px 7px 0px;
  font-size: 1em;
  background: linear-gradient(272deg, #c7f5c9, transparent);
  color: darkgreen;
}
.addNewFarmuser > input {
  float: right;
  width: 11vw;
  border: none;
  border-bottom: 1px solid;
  background-color: #ffffff;
  margin: 5px 1em;
  height: 2.3vh;
  text-align: center;
  outline: none;
}
.addNewFarmuser > button {
  float: right;
  width: 8vw;
  border: none;
  background-color: #fff;
  margin: 3px 5px;
  border: 1px solid green;
  border-radius: 13px;
  color: darkgreen;
  height: 2.5vh;
}
.addNewFarmuser > button:hover {
  background-color: green;
  color: #fff;
}
.addNewFarmuser > button > i {
  float: right;
  margin: 2px;
}
.farmuserDetailInfo {
  float: right;
  height: 37vh;
  width: 49vw;
}
.farmuserDetailInfoBox-info {
  float: right;
  width: 18vw;
  height: 37vh;
}
.farmuserDetailInfoBox-info h4 {
  float: right;
  font-size: 1em;
  clear: right;
  margin-top: 1em;
  width: 7vw;
  padding-right: 0.4em;
  border-bottom: 1px dashed #737373;
  margin-right: 0.4em;
}
.farmuserDetailInfoBox-info h5 {
  float: right;
  font-size: 1em;
  margin-top: 1em;
  color: darkgreen;
  text-align: right;
  border-bottom: 1px solid #737373;
  padding: 0px 4px;
  width: 58%;
  height: 2vh;
  font-weight: bold;
}
.farmuserDetailInfoBox-info p {
  float: right;
  padding: 1em;
  height: fit-content;
  width: 100%;
  clear: both;
  color: darkgreen;
  font-weight: bold;
}
.farmuserDetailInfoBox-info p i {
  float: right;
  font-size: 1em;
  font-family: "Dirooz";
  border-bottom: 1px dashed #737373;
  font-style: normal;
  width: 6.1vw;
  margin-left: 10px;
  color: #434343;
  font-weight: normal;
}
.farmuserDetailInfoBox-aboutmy {
  float: right;
  width: 15vw;
  height: 37vh;
  margin-left: 1em;
}
.farmuserDetailInfoBox-aboutmy p {
  float: right;
  padding: 0em 0.5em 0.5em 0.5em;
  height: 32vh;
  width: 100%;
  clear: both;
  background-color: whitesmoke;
  overflow-y: auto;
  overflow-x: hidden;
  color: #f80;
}
.farmuserDetailInfoBox-aboutmy p::-webkit-scrollbar {
  width: 8px;
}
.farmuserDetailInfoBox-aboutmy p::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.farmuserDetailInfoBox-aboutmy p::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.farmuserDetailInfoBox-aboutmy p::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
.farmuserDetailInfoBox-aboutmy i {
  float: right;
  font-size: 1em;
  font-family: "Dirooz";
  font-weight: bold;
  border-bottom: 1px solid #3d3d3d;
  width: 100%;
  text-align: center;
  color: #3d3d3d;
  font-style: normal;
  margin-bottom: 1em;
}
.farmuserDetailInfoBox-expertise {
  float: right;
  width: 14vw;
  height: 27vh;
}
.farmuserDetailInfoBox-expertise span {
  float: right;
  width: 100%;
  height: 27vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.farmuserDetailInfoBox-expertise span i {
  float: right;
  width: 100%;
  text-align: center;
  font-family: "Dirooz";
  font-style: normal;
  font-size: 1em;
  font-weight: bold;
  border-bottom: 1px solid;
}
.farmuserDetailInfoBox-expertise span h6 {
  float: right;
  width: 96%;
  margin: 0.4em;
  background-color: #ecffd0;
  padding: 0.4em;
  color: #000;
}
.register-btn {
  float: left;
  background-color: darkgreen;
  border: 2px solid darkgreen;
  width: 23%;
  margin: 1em;
  padding: 1em 0em;
  color: #fff;
  font-size: 1.2em;
}
.register-btn:hover {
  background-color: #fff;
  color: darkgreen;
}

.search-box {
  float: right;
  width: 100%;
  margin: 10% 0%;
}
.search-box h6 {
  float: right;
  width: 100%;
  font-size: 1.4em;
  text-align: center;
  color: green;
  text-shadow: 0px 3px 5px #2eb85c;
}
.search-box input {
  float: right;
  margin-right: 39%;
  border: none;
  border-bottom: 1px solid darkgreen;
  height: 8vh;
  text-align: center;
  outline: none;
}
.search-box button {
  float: right;
  clear: both;
  margin-right: 45%;
  margin-top: 1em;
  border: none;
  background-color: darkgreen;
  padding: 0.6em 1em;
  font-size: 2em;
  color: #fff;
  transition: all 400ms;
}
.search-box button:hover {
  box-shadow: 0px 7px 8px 0px #000;
}

.not-expertise {
  width: 57%;
  text-align: center;
  margin-top: 5em;
  margin-bottom: 5em;
  font-size: 1.4em;
  color: red;
  float: right;
  background-color: #ededed;
  border-radius: 15px;
  margin-right: 4%;
}
</style>