<template>
  <div>
    <vue-element-loading
      :active="loading"
      text="دریافت اخرین دستورالعمل کارمند مزرعه..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <h5 class="farmuserSubForm-title">
      آخرین دستورالعمل ثبت شده توسط {{ farmuserName }}
    </h5>
    <div class="tipsBox" v-if="tipsInfo != null">
      <h4 class="tipsTitle">{{ tipsInfo.title }}</h4>
      <p class="tipsDesc">
        {{ tipsInfo.desc }}
      </p>
      <div class="tipsMore">
        <span style="cursor: pointer">
          <i class="fa fa-commenting-o" aria-hidden="true"></i>
          {{ tipsInfo.replyCount }}
        </span>
        <span style="margin-left: 2em">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          {{ tipsInfo.dateTime }}
        </span>
        <a
          v-for="(fitem, findex) of tipsInfo.files"
          :key="findex"
          :href="downloadLink(fitem)"
          target="_blank"
          ><i class="fa fa-download" aria-hidden="true"></i
        ></a>
      </div>
    </div>
    <h6
      v-else
      style="
        width: 100%;
        float: right;
        text-align: center;
        font-size: 2rem;
        margin-top: 3em;
        color: cornflowerblue;
      "
    >
      <i class="fa fa-file-o" aria-hidden="true"></i>
      کارمند مزرعه دستورالعملی ثبت نکرده است
    </h6>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";
export default {
  components: {},
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      tipsInfo: {
        title: "",
        desc: "",
        dateTime: "",
        replyCount: 0,
        files: [],
      },
    };
  },
  props: ["farmuserId", "farmuserName"],
  watch: {
    farmuserId: function () {
      this.GetTips();
    },
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetLastTips"]),

    async GetTips() {
      if (this.farmuserId) {
        this.loading = true;
        this.loadingText = "در حال دریافت اطلاعات...";
        let result = await this.GetLastTips({
          userId: this.farmuserId,
          userType: "FarmUser",
        });
        if (result.data) {
          this.tipsInfo = result.data;
        } else {
          this.tipsInfo = null;
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message}`,
            type: "info",
          });
        }
        this.loading = false;
      }
    },
    downloadLink(fileName) {
      return `${apiUrlRoot}/TipsFile/${fileName}`;
    },
  },
  mounted() {
    this.GetTips();
  },
  created() {},
};
</script>

  <style scoped>
.farmuserSubForm-title {
  float: right;
  width: 100%;
  text-align: right;
  padding: 8px 5px 7px 0px;
  font-size: 1em;
  background: linear-gradient(272deg, #c7f5c9, transparent);
  color: darkgreen;
}
.tipsBox {
  float: right;
  height: 37vh;
  width: 100%;
}
.tipsTitle {
  float: right;
  text-align: right;
  font-size: 1rem;
  margin: 0px;
  margin-right: 6%;
  font-weight: bold;
  color: #088f35;
}
.tipsDesc {
  float: right;
  width: 98%;
  height: 29vh;
  padding: 10px;
  color: #a1a1a1;
  overflow-y: auto;
  background-color: whitesmoke;
  margin: 1%;
}
.tipsMore {
  float: right;
  width: 100%;
}
.tipsMore a {
  float: right;
  text-align: center;
  height: 3vh;
  margin: 1% 2% 0px 0px;
  padding-top: 4px;
  color: #2196f3;
}
.tipsMore span {
  float: right;
  margin: 1% 2% 0px 0px;
  text-align: center;
  padding-top: 4px;
  color: #4a4a4a;
}
.tipsMore span i {
  float: right;
  margin-left: 5px;
  color: #f80;
  font-size: 16px;
}
</style>
