<template>
  <div class="farmListBox">
    <vue-element-loading
      :active="loading"
      text="دریافت لیست زمین ها..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <h5 class="farmuserSubForm-title">
      جزئیات اطلاعات کارمند مزرعه {{ farmuserName }}
    </h5>
    <h6
      v-if="farmsList.length == 0"
      style="
        width: 100%;
        text-align: center;
        font-size: 2em;
        margin-top: 4em;
        color: #ff9595;
      "
    >
      کارمند مزرعه هیچ زمینی ندارد
    </h6>
    <div v-else class="farm-list-box">
      <div class="farmItem" v-for="(item, index) in farmsList" :key="index">
        <img
          class="farmImg"
          :src="item.image"
          @error="
            () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
          "
        />
        <img
          class="productImg"
          @error="
            () => (item.productImage = require(`@/assets/img/notFound.png`))
          "
          :src="item.productImage"
          alt=""
        />
        <ul>
          <li>{{ item.title }}</li>
          <li>{{ item.product }}</li>
          <li>{{ item.stateCity }}</li>
          <li>{{ item.districtVilage }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";

export default {
  name: "FarmsList",
  components: {},
  data() {
    return {
      farmsList: [],
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
    };
  },
  props: ["farmuserId", "farmuserName"],
  watch: {
    farmuserId: function () {
      this.getFarmsList();
    },
  },
  methods: {
    ...mapActions("connectionFarmerFarmuser", [
      "FarmerFarmuserFarms",
    ]),

    async getFarmsList() {
      this.farmsList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.FarmerFarmuserFarms({
        farmuserId: this.farmuserId,
        canAdd:false,
      });
      if (result.data.length > 0) {
        this.farmsList = result.data;
        this.farmsList.forEach((element) => {
          element.image = `${apiUrlRoot}Farm/${element.image}`;
          element.productImage = `${apiUrlRoot}productImage/${element.productImage}`;
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getFarmsList();
  },
};
</script>

  <style scoped>
.farmListBox {
  width: 49vw;
  height: 42vh;
  overflow-y: auto;
}
.farmuserSubForm-title {
  float: right;
  width: 100%;
  text-align: right;
  padding: 8px 5px 7px 0px;
  font-size: 1em;
  background: linear-gradient(272deg, #c7f5c9, transparent);
  color: darkgreen;
}
.farm-list-box {
  width: 98%;
  height: 36vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.farmItem {
  width: 14vw;
  float: right;
  border-radius: 2em;
  background-color: #fff;
  margin: 1em;
  border: 1px solid #bbb;
  cursor: pointer;
  box-shadow: 0px 3px 10px -5px #000;
  position: relative;
  height: 29vh;
}

.farmItem .farmImg {
  float: right;
  width: 90%;
  height: 14vh;
  margin: 5%;
}
.farmItem .productImg {
  width: 10%;
  position: absolute;
  bottom: 8em;
  left: 8em;
}

.farmItem ul {
  float: right;
  margin: 0em;
  width: 100%;
  list-style: none;
  padding: 0em 0.5em;
}

.farmItem ul li {
  float: right;
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid #03c503;
  margin: 1px 0px;
  padding-right: 0.4em;
  font-family: "Dirooz";
  color: black;
  font-size: 1em;
  border-radius: 0px 4px 4px 0px;
}

.farmItemBtn:hover {
  box-shadow: 0px 7px 16px -4px #000;
}
</style>
